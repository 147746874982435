import React from "react";
// import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
// import { GiRibbonMedal, GiHammerNails } from "react-icons/gi";
// import { AiOutlineTeam } from "react-icons/ai";
// import { IoMdHammer } from "react-icons/io";
function HeroVideo({ urlVideo, title, texts }) {
    // const { rpdata } = useContext(GlobalDataContext);
    return (
      <div>
        <div className="w-full relative content_video flex justify-center">
          <video
            playsInline
            autoPlay
            muted
            loop
            className="w-full md:h-full object-cover"
          >
            <source src={urlVideo} type="video/mp4" />
          </video>
          <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[30px] md:bottom-[100px] lg:bottom-[120px] md:text-start text-center text-white">
            <div className="w-full flex flex-col text-center  items-center md:items-start justify-center">
              <div className="w-full md:w-3/5 flex flex-col justify-center  items-center">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/15.png?alt=media&token=a41dcb0f-e29e-4565-a47b-72ef22cc2c49"
                  className="w-32 h-3w-32 md:w-64 md:h-64 object-cover block md:hidden"
                  alt="años de experiencia"
                />
                <h1 className="text-white text-center md:text-start text-[30px] md:text-[48px] font-bold">
                  {title}
                </h1>
                <p className="text-white text-center md:text-start">{texts}</p>
              </div>
              <ButtonContent btnStyle={"three"} />
            </div>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/15.png?alt=media&token=a41dcb0f-e29e-4565-a47b-72ef22cc2c49"
              className="w-32 h-3w-32 md:w-48 md:h-4w-48 object-cover absolute right-20 hidden md:block"
              alt="años de experiencia"
            />
          </div>
        </div>
        {/* <div className=" md:w-[70%] w-[100%] bg-2 text-white md:-mt-14 -mt-1 relative md:py-14 py-2 homi">
                    <ul className="flex md:flex-row flex-col justify-around ml-5 md:ml-0">
                        <h5 className="flex items-center" data-aos="zoom-in">
                            <GiHammerNails
                                fontSize={70}
                                className="text-white"
                            />
                            BEST SERVICE</h5>
                        <h5 className="flex items-center" data-aos="zoom-in" data-aos-duration="1000">
                            <AiOutlineTeam
                                fontSize={70}
                                className="text-white"
                            />
                            PROFESSIONAL TEAM</h5>
                        <h5 className="flex items-center" data-aos="zoom-in" data-aos-duration="1500">
                            <GiRibbonMedal
                                fontSize={70}
                                className="text-white"
                            />BEST QUALITY</h5>
                    </ul>
                </div> */}
      </div>
    );
}
export default HeroVideo;